@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    overflow-x: hidden;
    position: relative;
    width: 100%;
}

body {
    margin: 0;
    font-family: 'Lexend', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    max-width: 100vw;
    touch-action: pan-y pinch-zoom;
}

code {
    font-family: 'Lexend', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* Scroll Animations */
.scroll-animation {
    opacity: 0;
    transform: scale(1.1);
    transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.scroll-animation.animate {
    opacity: 1;
    transform: scale(1);
}

/* Different animation variants */
.scroll-animation.zoom-out {
    transform: scale(1.1);
}

/* Stagger delays for multiple elements */
.scroll-animation:nth-child(2) {
    transition-delay: 0.1s;
}

.scroll-animation:nth-child(3) {
    transition-delay: 0.2s;
}

.scroll-animation:nth-child(4) {
    transition-delay: 0.3s;
}
